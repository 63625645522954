import React from "react"
import Layout from "../components/layout"
import EmailSub from "../components/emailSub"
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import SEO from "../components/seo"
import styled from "@emotion/styled"
import blogImg from "../images/robert-with-grandson.jpg"
import aboutImg from "../images/about.jpg"
import jimmyImg from "../images/jimmy-cover.jpg"
import chosenBook from "../images/chosen-people.png"
import hero from "../images/hero.jpg"

const HomePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4.4rem;
`

/* Hero */
const HeroContainer = styled.div`
  display: inline-block;
  color: #ededed;
  height: 400px;
  margin-bottom: 2rem;
  background-image: url(${hero});
  background-size: cover;
  background-position: 60% 50%;

  @media (min-width: 1024px) {
   background-position: 60% 35%;
  }

  @media (min-width: 1250px) {
    height: 750px;
  }

  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`

const HeroText = styled.div`
  position: relative;
  z-index: 2;
  top: 2.25rem;
  left: 1rem;

  @media (min-width: 768px) {
    left: 2rem;
  }

  @media (min-width: 1024px) {
    left: 6rem;
    top: 5rem;
  }

  @media (min-width: 1250px) {
    top: 7rem;
    left: 7rem;
  }
`

const HeroLine = styled.div`
  background-color: #7798ab;
  width: 1rem;
  height: 0.05rem;
  margin-top: 0.5rem;

  @media (min-width: 1250px) {
    width: 2rem;
  }
`

const HeroTitle = styled.h1`
  margin: 0;
  font-size: 1.75rem;

  @media (min-width: 768px) {
    font-size: 2.5rem;
  }

  @media (min-width: 1250px) {
    font-size: 5rem;
  }
`

const HeroTagline = styled.h3`
  margin-top: 1rem;
  width: 50%;
  font-size: 1.25rem;
  line-height: 1.5rem;

  @media (min-width: 768px) {
    width: 40%;
    font-size: 1.75rem;
    line-height: 2.2rem;
  }

  @media (min-width: 1250px) {
    font-size: 3rem;
    line-height: 3.75rem;
  }
`

/* Subs Section */

const SubscribeContainer = styled.div`
  background-color: #7798ab;
  border-top: 0.25rem solid black;
  border-bottom: 0.25rem solid black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
          flex-direction: column;

  @media (min-width: 1250px) {
    margin-top: 3rem;
  }
`

const SubsText = styled.div`
  text-align: center;
  margin: 3rem 2rem 0 2rem;
`

const SubsTitle = styled.h2`
  color: #ededed;
  border-bottom: 1px solid #ededed;
  margin: 0 auto;
  margin-bottom: 1rem;
  width: 75%;
  padding-bottom: 0.2rem;

  @media (min-width: 768px) {
    width: 40%;
  }

  @media (min-width: 1024px) {
    width: 30%;
  }

  @media (min-width: 1250px) {
    width: 20%;
  }
`

const SubsInfo = styled.p`
  color: #ededed;
  width: 80%;
  margin: 0 auto;
  font-size: 1.1rem;
  letter-spacing: 0.1rem;

  @media (min-width: 768px) {
   font-size: 1.3rem;
   width: 55%;
   line-height: 1.75rem;
  }
`

/* Second Homepage Nav */

const ExploreContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  margin: 2rem 0;
`

const ExploreTitle = styled.h2`
  font-size: 2rem;
  width: 30%;
  padding-bottom: 0.25rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid black;
  font-weight: bold;
  color: black;

  @media (min-width: 768px) {
    width: 20%;
    margin-bottom: 3rem;
  }

  @media (min-width: 1250px) {
    width: 10%;
    margin: 3rem 0 4rem 0;
  }
`

const ExploreWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

const ExploreSection = styled.a`
  width: 80%;
  filter: drop-shadow(0 0 0.4rem rgba(0, 0, 0, 0.4));
  cursor: pointer;
  
  @media (min-width: 768px) {
    width: 50%;
  }

  @media (min-width: 1250px) {
    width: 18%;
    margin: 0 2.25rem;
  }
`

const ExploreImg = styled.img`
  width: 350px;
  height: 300px;
  object-fit: cover;
  object-position: 80% 0%;

  @media (min-width: 768px) {
    width: 280px;
  }

  @media (min-width: 1250px) {
    height: 350px;
  }
`

const ExploreNavTitle = styled.h3`
  width: 80%;
  margin: 0 auto;
  position: relative;
  top: -55px;
  font-size: 1.75rem;
  padding: 1.5rem 0 1rem 0;
  background-color: #7798ab;
  color: #ededed;
  letter-spacing: 0.1rem;

  @media (min-width: 768px) {
    width: 70%;
  }

  @media (min-width: 1024px) {
    width: 50%;
  }

  @media (min-width: 1250px) {
    width: 80%;
  }
`

/* Movie Section */

const MovieContainer = styled.div`
  background-color: #284e39;
  color:#ededed;
  border-top: 0.25rem solid black;
  border-bottom: 0.25rem solid black;

  @media (min-width: 768px) {
    display: flex;
  }
`

const MovieTextSection = styled.div`
  margin: 2rem 0;
  text-align: center;

  @media (min-width: 768px) {
    width: 50%;
  }
  
  @media (min-width: 1024px) {
    margin-top: 3rem;
  }

  @media (min-width: 1250px) {
    margin: 4rem 0 0 3rem;
  }
`

const MovieSectionTitle = styled.h2`
  margin: 0 auto;
  margin-bottom: 2rem;
  width: 90%;

  @media (min-width: 768px) {
    text-align: left;
    width: 80%;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
    font-size: 2rem;
  }
  
  @media (min-width: 1024px) {
    font-size: 2.25rem;
    margin-bottom: 2rem;
  }

  @media (min-width: 1250px) {
    font-size: 4rem;
  }
`

const MovieSectionLink = styled.a`
  color: #ededed;
  background-color: black;
  padding: 1rem;
  font-style: italic;
  font-weight: bold;
  letter-spacing: 0.05rem;
  font-size: 1.1rem;
  -webkit-transition: background-color 0.5s ease;
  -o-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;

  &:hover {
    background-color: #ededed;
    border: 1px solid black;
    color: black;
    -webkit-transition: background-color 0.5s ease;
    -o-transition: background-color 0.5s ease;
    transition: background-color 0.5s ease;
  }

  @media (min-width: 768px) {
    display: flex;
    align-self: flex-start;
    justify-content: center;
    width: 150px;
    padding: 1rem 0;
    margin-left: 1.5rem; 
  }
  @media (min-width: 1250px) {
    width: 250px;
    font-size: 1.5rem;
  }
`

const MovieImgSection = styled.div`
  border-top: 0.25rem solid black;

  @media (min-width: 768px) {
    width: 50%;
    border-top: none;
    border-left: 0.25rem solid black;
  }
`

const FanQuoteContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 2rem;
`

const FanQuote = styled.h3`
  font-size: 1.6rem;
  line-height: 2rem;
  margin: 1rem;
`

const FanName = styled.p`
  font-size: 1.5rem;
  font-style: italic;
`

const IndexPage = (props) => (
  <Layout>
    <SEO title="Home" />
    <HomePageContainer>
      <HeroContainer>
        <HeroText>
          <HeroTitle>Robert Whitlow</HeroTitle>
          <HeroLine>&nbsp;</HeroLine>
          <HeroTagline>Accomplished attorney and best selling author of faith-based legal dramas</HeroTagline>
        </HeroText>
      </HeroContainer>
      <SubscribeContainer>
        <SubsText>
          <SubsTitle>Become An Insider</SubsTitle>
          <SubsInfo>Sign up for the inside scoop on new books, freebies and the latest news</SubsInfo>
        </SubsText>
        <EmailSub />
      </SubscribeContainer>
      <ExploreContainer>
        <ExploreTitle>Explore</ExploreTitle>
        <ExploreWrapper>
          <ExploreSection href="/about/">
            <ExploreImg src={aboutImg} alt="Robert with wife" />
            <ExploreNavTitle>About</ExploreNavTitle>
          </ExploreSection>
          <ExploreSection href="/books/">
            <ExploreImg src={chosenBook} alt="Chosen Book Cover" />
            <ExploreNavTitle>Books</ExploreNavTitle>
          </ExploreSection>
          <ExploreSection href="/movies/">
            <ExploreImg src={jimmyImg} alt="Jimmy Movie Poster" />
            <ExploreNavTitle>Movies</ExploreNavTitle>
          </ExploreSection>
          <ExploreSection href="/blog/">
            <ExploreImg src={blogImg} alt="Robert Whitlow with Grandson" />
            <ExploreNavTitle>Blog</ExploreNavTitle>
          </ExploreSection>
        </ExploreWrapper>
      </ExploreContainer>
      <MovieContainer>
        <MovieTextSection>
          <MovieSectionTitle>Enjoy Robert's stories in front of your favorite screen</MovieSectionTitle>
          <MovieSectionLink href="/movies/">Explore Movies</MovieSectionLink>
        </MovieTextSection>
        <MovieImgSection>
          <Img fluid={props.data.homeTwo.childImageSharp.fluid} style={{height: '100%'}} alt="Robert Whitlow Movies on various devices"/>
        </MovieImgSection>
      </MovieContainer>
      <FanQuoteContainer>
        <FanQuote>"I love your books and tell all my friends they need to read them.  They are believable, wonderful and hard to put down.  Last one:  'I will stop as soon as I get to the end of the chapter.'  Hours later: '3:30a already?  Clock goes off at 7:30a, I can read a couple more pages.'  Your books are wonderful, detrimental to my sleep, but wonderful.  Thank you, thank you, thank you."</FanQuote>
        <FanName>Carla M</FanName>
      </FanQuoteContainer>
    </HomePageContainer>
  </Layout>
)

export default IndexPage


export const query = graphql`
query {
  homeTwo: file(relativePath: { eq: "devices-mockup-movies.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 900, quality: 100) {
        ...GatsbyImageSharpFluid
        presentationWidth
      }
    }
  }
}
`